/* You can add global styles to this file, and also import other style files */
@import "swiper/scss";
@import "swiper/scss/keyboard";
@import "swiper/scss/pagination";
@import "~@ng-select/ng-select/themes/default.theme.css";
// ... or
// @import "~@ng-select/ng-select/themes/material.theme.css";

.swiper-pagination-bullet {
  // background-color: var(--ion-color-medium) !important;
  background: rgba(255, 255, 255, 0.33);
  opacity: 1;
}
.swiper-pagination-bullet-active {
  background-color: #ffffff !important;
  width: 40px;
  border-radius: 4px;
}

* {
  font-family: "Poppins";
}
body {
  overflow: hidden;
}

input,
select,
textarea,
page-item {
  border: 1px solid #c3c7e5 !important;
  box-shadow: none !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 155%;
  color: #000000 !important;

  &:focus {
    border: 1px solid #1cbbce !important;
    // font-size: 12px;
  }
  &::placeholder {
    color: #c3c7e5 !important;
    font-size: 12px;
  }
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.search-box {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;

  .search-container {
    background: #f6f6f6;
    border-radius: 6px;
    padding: 9px;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 4px;
    input {
      border: none !important;
      font-size: 12px;
      background: transparent;
      box-shadow: none !important;
      font-style: normal;
      font-weight: 400;
      width: 100%;
      //   font-size: 12px;
      line-height: 155%;
      color: #000000 !important;

      &:focus {
        border: none !important;
        box-shadow: none !important;
        outline: none !important;
      }
    }
  }
  .search-btn {
    background: #000000;
    border-radius: 4px;
    padding: 5px;
    img {
      width: 18px;
    }
  }
}

// .table {
//   padding-bottom: 16rem !important;
// }
.profile_img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.nav_me {
  display: flex;
  flex-direction: row;
  gap: 12px;

  a {
    display: flex;
    flex-direction: row;
    gap: 12px;
    text-decoration: none;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    letter-spacing: -0.02em;

    color: #a5a3a9;
    &:hover {
      color: inherit;
    }

    &.active {
      color: #292d32;
      &:hover {
        color: inherit;
      }
    }
  }
}

.nav_me_two {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  a {
    display: flex;
    flex-direction: row;
    gap: 12px;
    text-decoration: none;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.28px;
    /* identical to box height */

    letter-spacing: -0.02em;

    color: #a5a3a9;
    &:hover {
      color: inherit;
    }

    &.active {
      color: #292d32;
      &:hover {
        color: inherit;
      }
    }
  }
}

.mt-36 {
  margin-top: 36px;
}

.dropbtn {
  background-color: transparent;
  color: #292d32;
  border-radius: 2px;
  // background: #eee;
  // padding: 16px;
  border: none;
  cursor: pointer;
  padding: 5px 10px;

  color: #000;
  text-align: center;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* identical to box height */
  letter-spacing: -0.02em;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  svg {
    fill: #292d32;
  }

  &.active {
    padding-bottom: 10px;
    color: #1cbbce;
    border-bottom: 2px solid #1cbbce;
    svg {
      fill: #1cbbce;
    }
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  font-size: 12px;
  line-height: 16px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 999999;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: transparent;
}

.card {
  border-radius: 12px !important;
  border: none !important;

  .box {
    min-height: 480px;
  }
}

.card-container-one {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 10px;

  width: fit-content;
  height: fit-content;
  cursor: pointer;

  /* Black/50 */

  border: 1px solid #53545cd5;
  border-radius: 4px;

  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    display: flex;
    align-items: center;
    text-align: center;

    /* Black/50 */

    color: #53545c;
  }
}

.card-chart {
  margin-top: 20px;
}

.row_me {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  width: 50%;
}

.border_me {
  border: 1px solid #53545c8b;
  padding: 7px;
  border-radius: 6px;

  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "DM Sans" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;

  option {
    color: #000;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "DM Sans" !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
  }
}

.sort {
  width: 11vh !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 11px !important;
  line-height: 13px !important;
  /* Black/50 */

  color: #53545c !important;
}

.infor_with_search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  .infor_box {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 5px;

    p {
      margin: 0;
      padding: 0;
      color: #979797;
      font-family: "DM Sans";
      font-size: 12px;
      font-style: italic;
      font-weight: 400;
      line-height: 125%; /* 15px */
    }
  }
}

.infor {
  padding: 5px 10px;

  background: #eeeeee;
  border-radius: 4px;

  p {
    margin: 0px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #292d32;
  }
}

.user_profile {
  flex-direction: row;
  gap: 10px;
  align-items: center;

  .gradient-box {
    display: flex;
    align-items: center;
    //width: 50vw;
    width: 36px;
    height: 36px;

    position: relative !important;
    box-sizing: border-box;

    $border: 5px;
    color: #fff;
    background: #fff;
    background-clip: padding-box; /* !importanté */
    border: solid $border transparent; /* !importanté */
    border-radius: 50%;

    &:before {
      content: "";
      position: absolute;
      top: -1.5px;
      right: -6px;
      bottom: 0px;
      left: 3px;
      /* z-index: -1; */
      margin: -$border; /* !importanté */
      border-radius: inherit; /* !importanté */
      background: linear-gradient(
        180deg,
        #1cbbce 0%,
        rgba(12, 102, 113, 0.3125) 50%,
        rgba(253, 228, 40, 0.02) 100%
      ) !important;
    }
    img {
      width: 36px;
      height: 36px;
      border: 1px solid #ffffff;
      filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0));
      border-radius: 20px;
      object-fit: cover;
    }

    img.product {
      width: 36px;
      height: 48px;
      border: 1px solid #ffffff;
      filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0));
      object-fit: cover;
      // border-radius: 20px;
    }
  }

  span {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    // line-height: 17px;
    white-space: nowrap;
    // width: 6rem;
    // overflow: hidden;
    // text-overflow: ellipsis;

    /* Text Color/Text 1 */

    color: #1b212d;
  }

  .name_circle {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    // border: 1px solid black;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .name {
    display: flex;
    flex-direction: column;
    // gap: 10px;
    align-items: start;
  }

  small {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    // line-height: 147%;
    /* identical to box height, or 18px */

    color: #979797;
  }

  .name.discount {
    display: flex;
    flex-direction: column;
    // gap: 10px;
    align-items: start;

    span {
      font-weight: bolder;
      color: #1cbbce !important;
    }

    small {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      // line-height: 147%;
      /* identical to box height, or 18px */

      color: #ed1c25;
    }
  }
}

.user_email,
.user_tel,
.user_balance,
.user_credit,
.user_debit {
  span {
    align-items: center;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height, or 21px */

    color: #000000;
  }

  &.lock {
    span {
      color: #991094;
    }
  }
  &.savings {
    span {
      color: #377dff;
    }
  }
  &.rent {
    span {
      color: #ff5c00;
    }
  }
  &.drop {
    span {
      color: #1c1b57;
    }
  }
  &.success {
    span {
      color: #037641;
    }
  }
  &.danger {
    span {
      color: #f00;
    }
  }
}
.gap_me {
  display: flex;
  flex-direction: row;
  width: 100% !important;
  gap: 20px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 147%;
}
.gap_me {
  .time {
    color: #979797;
  }
}

.user_balance {
  span {
    color: #1cbbce;
  }
}

.user_credit {
  span {
    color: #279361;
  }
}

.user_debit {
  span {
    color: #ed1c25;
  }
}
.link {
  span {
    color: #1cbbce;
  }
}

.status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  padding: 3px 12px;
  gap: 10px;
  border-radius: 100px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  height: fit-content;

  &.pending {
    background: rgba(246, 111, 71, 0.2);
    color: #f66f47;
  }

  &.active {
    background: rgba(28, 187, 206, 0.2);
    color: #1cbbce;
  }

  &.success {
    background: rgba(0, 134, 6, 0.2);
    color: #008606;
  }

  &.failed {
    background: rgba(237, 28, 37, 0.2);
    color: #ed1c25;
  }

  &.referral {
    background: rgba(12, 16, 90, 0.2);
    color: #0c105a;
  }

  &.warning {
    background: rgba(90, 68, 12, 0.2);
    color: #565a0c;
  }
}

.status-no-outline {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  padding: 3px 12px;
  gap: 10px;
  border-radius: 100px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;

  &.pending {
    // background: rgba(246, 111, 71, 0.2);
    color: #f66f47;
  }

  &.active {
    // background: rgba(28, 187, 206, 0.2);
    color: #1cbbce;
  }

  &.success {
    // background: rgba(0, 134, 6, 0.2);
    color: #008606;
  }

  &.failed {
    // background: rgba(237, 28, 37, 0.2);
    color: #ed1c25;
  }
}

.cover_list {
  display: flex;
  flex-direction: row;
  gap: 50px;

  .list {
    padding: 6px;

    label {
      text-decoration: none;
      font-family: "DM Sans" !important;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: #292d32;
      /* identical to box height */

      letter-spacing: -0.02em;
    }
  }

  .list input[type="radio"]:checked + label {
    padding-bottom: 10px;
    color: #1cbbce;
    border-bottom: 2px solid #1cbbce;
  }
}

.form-check-input:checked {
  background-color: #1cbbce !important;
  border-color: #1cbbce !important;
}

.cover_list_circle {
  display: flex;
  flex-direction: row;
  gap: 34px;

  .list {
    padding: 6px;

    label {
      img {
        border-radius: 50%;
        width: 20px;
        height: 20px;
        margin-bottom: 7px;
      }
      p {
        text-decoration: none;
        font-family: "DM Sans" !important;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: #292d32;
        /* identical to box height */

        letter-spacing: -0.02em;
      }
    }
  }

  .list input[type="radio"]:checked + label {
    // padding-bottom: 10px;
    color: #1cbbce;
    // border-bottom: 2px solid #1cbbce;
    img {
      border: 2px solid #1cbbce;
    }
    p {
      color: #1cbbce;
    }
  }
}
.table {
  width: 100%;
    max-height: 400px; /* You can adjust the height */
    overflow: auto;
}
.table tr {
  align-items: center;
  &:hover {
    cursor: pointer;
    background: #f6f6f665;
  }

  & th {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.02em;

    color: #292d32;
  }
}
.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 62.5%; // 1rem = 10px    100% = 16px
  font-family: "Poppins";
}

.modify__search {
  display: flex;
  flex-direction: row;
  align-items: end;
  gap: 20px;
  // width: 70%;
}

.cursor {
  cursor: pointer;
}

/* Load Spinner  */

.lds-spinner {
  display: flex;
  justify-content: center;
}

.lds-spinner--center {
  align-items: center !important;
}

.lds-spinner::after {
  content: "";
  width: 20px;
  height: 20px;
  border: 2px solid #dddddd;
  border-top-color: #30323e00;
  border-bottom-color: #30323e00;
  border-radius: 50%;
  animation: lds-spinner 1s ease infinite;
}

@keyframes lds-spinner {
  to {
    transform: rotate(1turn);
  }
}

/* End Load Spinner */

div.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 16, 16, 0.5);
  z-index: 999999999999999;
}

@-webkit-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-ms-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.uil-ring-css {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 200px;
  height: 200px;
}
.uil-ring-css > div {
  position: absolute;
  display: block;
  width: 160px;
  height: 160px;
  top: 20px;
  left: 20px;
  border-radius: 80px;
  box-shadow: 0 6px 0 0 #ffffff;
  -ms-animation: uil-ring-anim 1s linear infinite;
  -moz-animation: uil-ring-anim 1s linear infinite;
  -webkit-animation: uil-ring-anim 1s linear infinite;
  -o-animation: uil-ring-anim 1s linear infinite;
  animation: uil-ring-anim 1s linear infinite;
}

.fade.show {
  background: rgba(0, 0, 0, 0.5) !important;
}

.btn-link {
  --bs-btn-color: #1cbbce !important;
  --bs-btn-hover-color: #1cbcce7d !important;
  --bs-btn-active-color: #1cbcce7d !important;
}

.page-link {
  color: #1cbbce !important;
}

.active > .page-link,
.page-link.active {
  z-index: 3;
  color: #ffffff !important;
  background-color: #1cbbce !important;
  border-color: #1cbbce !important;
}

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

$base-color: #b76a33;
$disable-background-color: #eceeef;
.multiselect-dropdown {
  position: relative;
  width: 100%;
  font-size: inherit;
  font-family: inherit;
  .dropdown-btn {
    display: inline-block;
    border: 1px solid #adadad;
    width: 100%;
    padding: 6px 12px;
    margin-bottom: 0;
    font-weight: normal;
    line-height: 1.52857143;
    text-align: left;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border-radius: 4px;
    .selected-item-container {
      display: flex;
      float: left;
      max-width: 93%;
      .selected-item {
        border: 1px solid $base-color;
        margin-right: 4px;
        margin-bottom: 4px;
        background: $base-color;
        padding: 0px 5px;
        color: #fff;
        border-radius: 2px;
        float: left;
        span {
          overflow: hidden;
          text-overflow: ellipsis;
        }
        a {
          text-decoration: none;
        }
      }
    }
    .selected-item:hover {
      box-shadow: 1px 1px #959595;
    }
    .dropdown-multiselect__caret {
      line-height: 16px;
      display: block;
      position: absolute;
      box-sizing: border-box;
      width: 40px;
      height: 38px;
      right: 1px;
      top: 0px;
      padding: 4px 8px;
      margin: 0;
      text-decoration: none;
      text-align: center;
      cursor: pointer;
      transition: transform 0.2s ease;
    }
    .dropdown-multiselect__caret:before {
      position: relative;
      right: 0;
      top: 65%;
      color: #999;
      margin-top: 4px;
      border-style: solid;
      border-width: 8px 8px 0 8px;
      border-color: #999999 transparent;
      content: "";
    }
    .dropdown-multiselect--active .dropdown-multiselect__caret {
      transform: rotateZ(180deg);
    }
  }
  .disabled {
    & > span {
      background-color: $disable-background-color;
    }
  }
}

.dropdown-list {
  position: absolute;
  padding-top: 6px;
  width: 100%;
  z-index: 9999;
  border: 1px solid #ccc;
  border-radius: 3px;
  background: #fff;
  margin-top: 10px;
  box-shadow: 0px 1px 5px #959595;
  ul {
    padding: 0px;
    list-style: none;
    overflow: auto;
    margin: 0px;
  }
  li {
    padding: 6px 10px;
    cursor: pointer;
    text-align: left;
  }
  .filter-textbox {
    border-bottom: 1px solid #ccc;
    position: relative;
    padding: 10px;
    input {
      border: 0px;
      width: 100%;
      padding: 0px 0px 0px 26px;
    }
    input:focus {
      outline: none;
    }
  }
}

.multiselect-item-checkbox:hover {
  background-color: #e4e3e3;
}
.multiselect-item-checkbox input[type="checkbox"] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.multiselect-item-checkbox input[type="checkbox"]:focus + div:before,
.multiselect-item-checkbox input[type="checkbox"]:hover + div:before {
  border-color: $base-color;
  background-color: #f2f2f2;
}

.multiselect-item-checkbox input[type="checkbox"]:active + div:before {
  transition-duration: 0s;
}

.multiselect-item-checkbox input[type="checkbox"] + div {
  position: relative;
  padding-left: 2em;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  margin: 0px;
  color: #000;
}

.multiselect-item-checkbox input[type="checkbox"] + div:before {
  box-sizing: content-box;
  content: "";
  color: $base-color;
  position: absolute;
  top: 50%;
  left: 0;
  width: 14px;
  height: 14px;
  margin-top: -9px;
  border: 2px solid $base-color;
  text-align: center;
  transition: all 0.4s ease;
}

.multiselect-item-checkbox input[type="checkbox"] + div:after {
  box-sizing: content-box;
  content: "";
  background-color: $base-color;
  position: absolute;
  top: 50%;
  left: 4px;
  width: 10px;
  height: 10px;
  margin-top: -5px;
  transform: scale(0);
  transform-origin: 50%;
  transition: transform 200ms ease-out;
}

.multiselect-item-checkbox input[type="checkbox"]:disabled + div:before {
  border-color: #cccccc;
}

.multiselect-item-checkbox
  input[type="checkbox"]:disabled:focus
  + div:before
  .multiselect-item-checkbox
  input[type="checkbox"]:disabled:hover
  + div:before {
  background-color: inherit;
}

.multiselect-item-checkbox
  input[type="checkbox"]:disabled:checked
  + div:before {
  background-color: #cccccc;
}

.multiselect-item-checkbox input[type="checkbox"] + div:after {
  background-color: transparent;
  top: 50%;
  left: 4px;
  width: 8px;
  height: 3px;
  margin-top: -4px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 0 0 3px 3px;
  border-image: none;
  transform: rotate(-45deg) scale(0);
}

.multiselect-item-checkbox input[type="checkbox"]:checked + div:after {
  content: "";
  transform: rotate(-45deg) scale(1);
  transition: transform 200ms ease-out;
}

.multiselect-item-checkbox input[type="checkbox"]:checked + div:before {
  animation: borderscale 200ms ease-in;
  background: $base-color;
}

.multiselect-item-checkbox input[type="checkbox"]:checked + div:after {
  transform: rotate(-45deg) scale(1);
}

@keyframes borderscale {
  50% {
    box-shadow: 0 0 0 2px $base-color;
  }
}

.truncate-overflow-0 {
  white-space: nowrap;
  width: 13.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  // background-color: #a6a9ab
}

.truncate-overflow-01 {
  white-space: nowrap;
  width: 7rem;
  overflow: hidden;
  text-overflow: ellipsis;
  // background-color: #a6a9ab
}

.truncate-overflow-1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  // background-color: #a6a9ab
}

.wide_it {
  width: 100% !important;
}

.truncate {
  white-space: nowrap !important;
  width: 10rem !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.gap_it {
  display: flex;
  flex-direction: row;
  gap: 18px;
  align-items: center;
}
.date_viewer {
  color: #0000009c;
  text-align: center;
  font-family: "DM Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 150% */
}

.tooltip {
  display: inline-block;
  position: relative;
  border-bottom: 1px dotted #666;
  text-align: left;
}

.tooltip .bottom {
  min-width: 200px;
  top: 40px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 10px 20px;
  color: #444444;
  background-color: #eeeeee;
  font-weight: normal;
  font-size: 13px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  display: none;
}

.tooltip:hover .bottom {
  display: block;
}

.tooltip .bottom i {
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
}

.tooltip .bottom i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: #eeeeee;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}

.edit_btn {
  display: flex;
  padding: 18px 6px;
  align-items: center;
  justify-content: center;
  gap: 2px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.05);

  span {
    color: #1cbbce;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 200% */
  }
}

.align-middle {
  margin-bottom: 100px !important;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  border: none !important;
}

.ng-select.ng-select-single .ng-select-container {
  // height: 39px;
  border-radius: 6px;
  width: 20vw;
  color: #c3c7e5 !important;
  font-size: 12px;
  height: 2.4rem;
  // padding: 0.375rem 2.25rem 0.375rem 0.75rem;
}


.empty_screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}



.empty-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;

  img {
    width: 107px;
    margin: 30px;
  }
}


.tab-content,
.tab-pane.fade {
  background: #fff !important;
}
.nav-tabs .nav-link.active {
  color: #1cbbce !important;
}
.nav-tabs .nav-link:hover {
  color: #1cbbce !important;
}
.nav-tabs .nav-link {
  color: #000;
}

.text-danger {
  font-size: 12px !important;
}
.image_rending {
  display: flex;
  justify-content: start;
  align-items: center;

  .list_image {
    width: 36px;
    height: 36px;
    border-radius: 50%;

    &:not(:first-child) {
        margin-left: -17px !important;
    }
  }

  span {
    padding-left: 10px;
  }
}

.no-arrow {
  appearance: none !important; /* Removes the default arrow on most browsers */
  -webkit-appearance: none !important; /* Removes the default arrow on WebKit-based browsers */
  -moz-appearance: none !important; /* Removes the default arrow on Firefox */
  background: transparent !important; /* Optional: remove the background if needed */
  padding-right: 1rem !important; /* Adjust padding to account for removed arrow */
  position: relative !important;
}

.no-arrow::after {
  content: '' !important; /* Optional: use this to style a custom arrow */
}




// Table fixing

.table-responsive {
  overflow-x: auto;
}

.my_table {
  max-height: 31rem; /* Set your desired height here */
  overflow-y: auto; /* Enables vertical scrolling */
}
.table-fixed-header {
  position: relative;
}
.table-fixed-header thead {
  background-color: white; /* Ensure the header background stays visible */
  z-index: 2;
  border-bottom: 2px solid #ddd; /* Optional: add a border for better visibility */
}

.table {
  table-layout: auto;
  width: 100%;
}

th,
td {
  white-space: nowrap;
  padding: 8px 12px;
  position: relative;
}

td {
  overflow: visible;
}
